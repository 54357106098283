import { useAxios } from "../assets/http";

/**
 * 获取jssdk配置相关参数
 * @param url 需要签名的页面url
 * @returns
 */
export function getJsSDKConfigApi(params: any) {
  return useAxios.post(`/app/share/getOffiAccountSignature`, {
    url: params.url
  });
}